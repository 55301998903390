import { Elm } from "./elm/Main.elm";

Elm.Main.init({
  flags: {
    url: window.location.href,
    authToken: null,
  },
  node: document.getElementById("app"),
});

navigator.serviceWorker.register(
  new URL("./service-worker.js", import.meta.url),
  { type: "module" }
);
