module Main exposing (..)

import Browser
import Browser.Navigation as Nav
import Html
import Model exposing (Flags, Model)
import Msg exposing (Msg(..))
import Update exposing (routeRequests, subscriptions, update)
import Url
import View



-- Init


init : Flags -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url key =
    case flags.authToken of
        Nothing ->
            ( Model.init key url flags.authToken
            , Nav.pushUrl key "/anmelden"
            )

        Just _ ->
            ( Model.init key url flags.authToken
            , routeRequests url
            )



-- View


view : Model -> Browser.Document Msg
view model =
    case model.url.path of
        "/" ->
            { title = "Narrativdatenbank"
            , body = [ View.layout <| Html.text "home" ]
            }

        "/neu" ->
            { title = "Narrativdatenbank"
            , body = [ View.layout <| Html.text "neu" ]
            }

        "/narrative" ->
            { title = "Narrative | Narrativdatenbank"
            , body = [ View.layout <| View.home model ]
            }

        "/metaphern" ->
            { title = "Metaphern | Narrativdatenbank"
            , body = [ View.layout <| Html.text "Metaphern" ]
            }

        "/statements" ->
            { title = "Statements | Narrativdatenbank"
            , body = [ View.layout <| Html.text "Statements" ]
            }

        "/zitate" ->
            { title = "Zitate | Narrativdatenbank"
            , body = [ View.layout <| Html.text "Zitate" ]
            }

        "/anmelden" ->
            { title = "Anmelden | Narrativdatenbank"
            , body = [ View.login ]
            }

        "/abmelden" ->
            { title = "Du wirst abgemeldet…"
            , body = [ View.logout ]
            }

        _ ->
            { title = "Seite nicht gefunden"
            , body = [ View.notFound ]
            }



-- Main


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }
